body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-page {
  background-color: #FFFCFA;
}



.navbar {
  display: flex;
  align-items: center;
  background-color: #1F4E75;
  height: 70px;
  padding: 0px 25px;
}

#nav--phone {
  margin-left: auto;
  color: white;
}

#nav--brand {
  color: white;
}

#hello {
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 20px;
}

#under-hello {
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 20px;
}

#picture-block {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

#start-img {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

#main-form {
  margin-top: 40px;
  max-width: 60%;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

/* .badge {
  display: inline-block;
  font-size: 12px;
  background: #39B54A;
  color: #fff;
  border-radius: 15px;
  padding: 2px 10px;
} */


.badge-custom {
  background-color: #39B54A;
  color: #fff;
}