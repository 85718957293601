.calc__container {
    margin: 0 auto;
    padding: 32px 140px 32px 140px;
    box-shadow: 0 12px 12px rgb(0 0 0 / 10%), 0 12px 12px rgb(0 0 0 / 4%), 0 12px 20px rgb(0 0 0 / 4%), 0 6px 5px rgb(0 0 0 / 4%), inset 0 5px 0 #3e4757;
    background-color: #fff;
    border-radius: 24px;
    width: 824px;
}

.calc__body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.calc__form {
    width: 536px;
}

.calc__progress {
    margin-left: 40px;
    width: 248px;
}

.driver-details {
    margin-bottom: 20px;
}

.date-picker {
    width: 100%;
}

.mb-20 {
    margin-bottom: 30px;
}

.input-group {
    width: 100%;
}

.lease-switch-group {
    display: flex;
    justify-content: space-between;
}

.phone-input {
    width: 345px !important;

    border-radius: 6px !important;
}

.phone-input-container {
    margin-bottom: 20px !important;
}

.inactive-text {
    color: grey;
}

.totals-vehicle {
    font-size: 12px;
}

.small-title {
    font-size: 16px !important;
}

@media only screen and (max-width: 415px) {
    .calc__container {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 0px 30px 30px 30px;
        width: auto;
    }

    .calc__body {
        display: flex;
        flex-direction: column-reverse;
    }

    .calc__form {
        width: auto;
    }

    .calc__progress {
        margin-left: 0;
        width: auto;
        padding-bottom: 15px;
    }

    .insurance_price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #insurance-quote {
        padding-right: 15px;
    }

    .your_vehicle {
        display: flex;
        justify-content: space-between;
    }

    .totals-vehicle {
        font-size: 16px;
    }

    hr {
        display: none;
    }
}